/************ Projects Css ************/
.project {
    padding: 80px 0;
    position: relative;
    background-color: black;
  }
  .project h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2%;
  }
  .project p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 2%;
    text-align: center;
    /* width: 90%; */
  }
  .project .nav.nav-pills {
    width: 72%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
  }
  .project .nav.nav-pills .nav-item {
    width: 33.33333%;
  }
  .project .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
  }
  .project .nav.nav-pills .nav-link::before {
      content: "";
      position: absolute;
      width: 0;
      height: 100%;
      background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
      top: 0;
      left: 0;
      z-index: -1;
      transition: 0.3s ease-in-out;
  }
  .project .nav.nav-pills .nav-link.active::before {
      width: 100% !important;
  }
  .project .nav.nav-pills .nav-link.active {
      border: 1px solid rgba(255, 255, 255, 1);
  }
  .nav-link#projects-tabs-tab-first {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 0px 0px 55px;
  }
  .nav-link#projects-tabs-tab-second {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .nav-link#projects-tabs-tab-third {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 55px 55px 0;
  }
  .proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 100%;
    height: 306px;
    max-width: 416px;
  }
  .proj-imgbx img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .proj-imgbx img.left-centered {
    object-position: center;
  }
  .proj-imgbx::before {
    content: "";
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
  }
  .proj-imgbx:hover::before {
    height: 100%;
  }
  .proj-txtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
  }
  .proj-imgbx:hover .proj-txtx {
    top: 50%;
    opacity: 1;
  }
  .proj-txtx h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: inline-block; 
  }
  .proj-txtx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block; 
  }
  .background-image-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
  }