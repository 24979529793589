/************ Skills Css ************/
.skill {
    padding: 0 0 50px 0;
    position: relative;
  }
.skill-bx {
    background: #151515;
    border-radius: 64px;
    text-align: center;
    padding: 60px 50px;
    margin-top: -60px;
}
.skill h2 {
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 3%;
}
.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    /* margin: 14px 0 75px 0; */
}
.skill .bottom {
    margin-bottom: 3%;
}
.skill .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px; /* Space between grid items */
    padding: 20px 0; /* Space around grid container */
}

.skill .grid-item {
    background-color: #6a1b9a; /* Purple background color */
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
    text-align: center; /* Center text alignment */
    transition: transform 0.3s ease; /* Smooth transform effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for a slight 3D effect */
    color: #ffffff; /* White text color */
    cursor: pointer;
}

.skill .grid-item:hover {
    transform: translateY(-5px); /* Lift item on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Slightly bigger shadow on hover */
    background-color: #7b1fa2; /* Darker purple on hover */
}

.skill .grid-item img {
    width: 80px; /* Image width */
    height: 80px; /* Image height */
    margin-bottom: 15px; /* Space between image and title */
}

.skill .grid-item h5 {
    margin-top: 10px;
    font-size: 18px; /* Title font size */
    font-weight: 500; /* Title font weight */
    /* color: #B8B8B8; Title text color */
}

.skill .skill-slider {
    width: 80%;
    margin: 0 auto;
    position: relative;
}
.skill .skill-slider .item img {
    width: 50%;
    margin: 0 auto 15px auto;
}
.background-image-left {
    top: 28%;
    position: absolute;
    bottom: 0;
    width: 40%;
    z-index: -4;
}

/* Popup Styles */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: #6a1b9a;
    color: white;
    padding: 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}

.popup-close {
    position: absolute;
    top: 1%;
    right: 3%;;
    background: none;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
}

.popup-content h2 {
    margin-top: 0;
    margin-bottom: 15px;
}

.popup-content .content {
    line-height: 1.6;
    color: white;
}